import '../CSS/TopNotice.css'
import React, { useState, useEffect } from 'react';

export default function TopNotice() {

    const [scrollingText, setScrollingText] = useState([]);

      useEffect(() => {
        fetchOptions();
      }, []);

      // const fetchOptions = async () => {
      //   try {
      //     const response = await fetch(`${baseURL}/api/topNotice`);
      //     if (response.ok) {
      //       const data = await response.json();
      //       if (data && Array.isArray(data) && data.length > 0) {
      //         const filteredOptions = data.filter(option => option.newsTitle !== null).map(option => ({
      //           name: option.newsTitle,
      //         }));
      //         setScrollingText(filteredOptions);
      //       } else {
      //         console.error('Invalid or empty data received');
      //       }
      //     } else {
      //       console.error('Error fetching options');
      //     }
      //   } catch (error) {
      //     console.error('Error fetching options:', error);
      //   }
      // };      
    
      const fetchOptions = async () => {
        try {
          const response = await fetch(`/api/NoticeForm`); 
          if (response.ok) {
            const data = await response.json();
            // console.log(data)
            const filteredOptions = data.recordset.filter(option => option.newsTitle !== null).map(option=> ({
              name: option.newsTitle,
            }));
            setScrollingText(filteredOptions);
          //   console.log(filteredOptions) 
          } else {
            console.error('Error fetching options');
          }
        } catch (error) {
            console.error('Error fetching options:', error);
          }
      };
   
    return (
        <>
        <div className="top-notice">
            <div className="non-scrolling-text">Latest News</div>
            <div className="scrolling-container">
            {scrollingText.length > 0 && scrollingText.map((newsTitle, index) => (
                <span className="scrolling-text text-white" key={index}>
                  {newsTitle.name}</span>
                ))}
            </div>
        </div>
      </>
    )
}
