import React from 'react'
import { Link } from 'react-router-dom';
import facebook from '../Images/logo/facebook.png'
import instagram from '../Images/logo/instagram.png'
import youtube from '../Images/logo/youtube.png'
import whatsapp from '../Images/logo/whatsapp.png'

export default function Footer() {
  return (
    <>
    <footer className="pt-5 mt-4 container text-white" style={{ maxWidth: '100%', margin:'0px',  backgroundColor:'#2D50A4' }}>
    <div className="row" style={{ backgroundColor:'rgb(248,249,250)'}}>
      <div className="col-9 offset-md-1 col-md-2 mb-3 pt-2 ">
        <h3 className='text-color'>Quick Links</h3>
        <ul className="nav flex-column ">
          <li className="nav-item mb-2 "><Link to="/" className="nav-link p-0 text-dark">Home</Link></li>
          <li className="nav-item mb-2"><Link to="/AboutUs" className="nav-link p-0 text-dark">About Us</Link></li>
          <li className="nav-item mb-2"><Link to="/SmartClass" className="nav-link p-0 text-dark">Facilities</Link></li>
          <li className="nav-item mb-2"><Link to="/ContactUs" className="nav-link p-0 text-dark">Contact Us</Link></li>
        </ul>
      </div>
      <div className="col-9 offset-md-1 col-md-4 mb-3 pt-2">
        <h3 className='text-color'>Reach Us</h3>
        <ul className="nav flex-column">
          {/* <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-dark">251, BAQAR GUNJ, FATEHPUR – 212601</Link></li>
          <li className="nav-item mb-2"><Link to="tel:+919651626121" className="nav-link p-0 text-dark">(+91) 965 162 6121</Link></li>
          <li className="nav-item mb-2"><Link to="mailto:fatehpurpublicSchool@gmail.com" className="nav-link p-0 text-dark">fatehpurpublicSchool@gmail.com</Link></li>
          <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-dark">Monday - Saturday, 08:00AM - 02:00 PM</Link></li> */}
          <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-dark">BAQARGANJ, FATEHPUR, UTTAR PRADESH – 271901</Link></li>
          <li className="nav-item mb-2"><Link to="tel:+8795949345" className="nav-link p-0 text-dark">(+91) 879 594 9345</Link></li>
          <li className="nav-item mb-2"><Link to="mailto:fatehpurpublicSchooll@gmail.com" className="nav-link p-0 text-dark">fatehpurpublicSchool@gmail.com</Link></li>
          <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-dark">Monday - Saturday, 08:00AM - 02:00 PM</Link></li>
        </ul>
      </div>
      <div className="col-md-4  mb-3 pt-2">
          <h5 className='text-color'>Fatehpur Public School</h5>
          <p className='text-dark'>Experience The Future OF Learning.</p>
          <div className="col-6 col-md-5 mb-3 pt-2 ">
          <ul className="nav flex-row">
            <li className="nav-item mb-2"><Link to='https://www.facebook.com'><img src={facebook} width="32" height="32" className="rounded-circle me-2" alt='footer-logo'/></Link></li>
            <li className="nav-item mb-2"><img src={instagram} width="32" height="32" className="rounded-circle me-2" alt='footer-logo' /></li>
            <li className="nav-item mb-2"><Link to='https://www.youtube.com'><img src={youtube} width="32" height="32" className="rounded-circle me-2" alt='footer-logo'/></Link></li>
            <li className="nav-item mb-2"><Link to='https://api.whatsapp.com/send?phone=8795949345'><img src={whatsapp} width="32" height="32" className="me-2" alt='footer-logo' /></Link></li>
            {/* <li className="nav-item mb-2"><Link to='https://api.whatsapp.com/send?phone=9651626121'><img src={whatsapp} width="32" height="32" className="me-2" alt='footer-logo' /></Link></li> */}
          </ul>
      </div>
      </div>
    </div>
    <div className="d-flex flex-column flex-sm-row justify-content-between pt-2 " style={{ backgroundColor:'#2D50A4'}}>
      <p className='text-white text-center'>© 2023 Fatehpur Public School, Inc. All rights reserved.</p>
      <p className='text-white text-center'><Link to='http://rayaninfosolutions.com/' className='nav-link'>Designed by: Rayan Info Solutions</Link></p>
    </div>
  </footer>
    </>
  )
}
