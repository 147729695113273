import React, { useState } from 'react';

const NoticeForm = () => {
    const [newsTitle, setNewsTitle] = useState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');


    const handleTitleChange = (e) => {
      setNewsTitle(e.target.value);
    };

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };
    
    const handlesDateChange = (e) => {
      const originalDate = e.target.value;
      const [year, month, day] = originalDate.split('-');
     
      const formattedDate = `${year}-${month}-${day}`;
      
      setStartDate(formattedDate);
    };
  
  
    const handleeDateChange = (e) => {
      const originalDate = e.target.value; 
     
      const [year, month, day] = originalDate.split('-');
     
      const formattedDate = `${year}-${month}-${day}`;
      
      setEndDate(formattedDate);
    };
    
    const handleSubmit = async (e) => {
      console.log('handlenoticeupload called');
      e.preventDefault();
      if (isSubmitting) {
        return; 
      }
      setIsSubmitting(true); 
      setSuccessMessage('Notice Uploaded successfully.');
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 2000); 

      const formData = new FormData();
      formData.append('newsTitle', newsTitle);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
    
      try {
        const response = await fetch(`/api/NoticeForm`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ newsTitle, startDate, endDate }),
        });
    
        if (response.ok) {
          console.log('Notice uploaded successfully');
          setNewsTitle('');
          setStartDate('');
          setEndDate('');
        } else {
          console.log("error in uploading")
        }
      } catch (error) {
        console.error('Error uploading notice:', error);
      }
      setIsSubmitting(false);
    }

  return (
    <>
{showModal && (
    <div className="modal-backdrop show"></div>
  )}
    <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
            <div className="modal-header">
              <h5 className="modal-title">Success Message</h5>
            </div>
            <div className="modal-body">
              {successMessage && <p className='text-success'>{successMessage}</p>}
            </div>
          </div>
        </div>
    </div>
     <div className='container mt-5 '>
        <h2 className='text-center c2'>Upload Notice</h2>
        <form className='form-container' onSubmit={handleSubmit}>
          <div>
            <label  className='form-label' htmlFor="title">Title:</label>
            <input  className='form-input'  name="newsTitle" type="text" id="newsTitle" value={newsTitle}  onChange={handleTitleChange} required />
          </div>
          <div>
            <label  className='form-label' htmlFor="sDate">Start Date:</label>
            <input  className='form-input'  name="startDate" type="Date" id="sDate" value={startDate} onChange={handlesDateChange} required />
          </div>
          <div>
            <label className='form-label'  htmlFor="eDate">End Date:</label>
            <input className='form-input'  name="endDate" type="Date" id="eDate" value={endDate} onChange={handleeDateChange} required />
          </div>
          <div className='mt-2'>
          <button className='form-submit' type="submit">Upload Notice</button>
          </div>
        </form>
      </div>
    </>
  )
}
export default NoticeForm;
