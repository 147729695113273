import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import logo from '../Images/admin-login-icon-15.png'

export const ComponentToPrintSlip = React.forwardRef((props, ref) => {
    const { mId } = props; 
    const [formData, setFormData] = useState({}); 
    const [loading, setLoading] = useState(true);
    // console.log('ComponentToPrint props:', props);


    useEffect(() => {
        fetch(`/api/PrintForm/${mId}`)
        .then((response) => response.json())
        .then(formDataArray => {
            // Access the first item in the array (assuming there's only one item)
            const formData = formDataArray[0];
            // console.log(formData);
            setFormData(formData);
            setLoading(false); // Set loading to false when formData is available
        })
    }, [mId]);     

      const inputStyle = {
        border: 'none',
        // Add any other custom styles you need
      };
      const labelStyle = {
        fontWeight: 'bold'
        // Add any other custom styles you need
      };
      const qrCodeValue = `http://192.168.1.10:3000/RegPdfReport/${mId}`;

    return (
      <div ref={ref} style={{margin:'10px 40px'}}>
        {/* <p className='font-weight-bold'>FORM NO. FPS ${formData.mId || ''}/PN</p> */}
        <hr className='mt-3'/>
        <div className='form-info mb-4'>
          <div className='img-container mt-5 mx-5' style={{ width:'50px'}}>
          <img src={logo} class="img-thumbnail" alt="..." />
          </div>
        <div className='text-container mt-5 mx-2'>
        <h4 className='text-center'>INTERVIEW ACKNOWLEDGEMENT SLIP</h4>
      <h6 className='text-center'>FOR CLASS PRE - NURSERY</h6>
      <p className='text-center'>SESSION 2023-2024<br/></p>
        </div>
      </div>
      {loading ? (
                <p>Loading formData...</p>
            ) : (
      <div className='form-fill'>
          <form className='form-inline'>
        <h6 className='text-center my-4'>Student Details</h6>
              <div className='row'>
              <div className='col-md-6'>
              <div className="form-group row ">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name" >Name:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.name || '' } readOnly  />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="date_of_birth">Date of Birth:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.dob.slice(0, 10) || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label'>Gender:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.gender || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Father Name:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.fatherName || '' } readOnly />
                  </div>
              </div>
              </div>
  
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Mother Name:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.motherName || '' } readOnly />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Contact No:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="number" className="form-control" value={formData.mobNo || '' } readOnly  />
                  </div>
              </div>
              </div>
  
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Whatsapp No:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="number" className="form-control" value={formData.whatsappNo || '' } readOnly />
                  </div>
              </div>
              </div>

              <div className="form-group row mb-2">
                  <label style={labelStyle}  className='lcol col-form-label' htmlFor="address">Address:</label>
                <div className='col'>
                  <textarea style={inputStyle} className="form-control" value={formData.address || '' } readOnly ></textarea>
              </div>
              </div>
              <hr />
              <div className='container'>
                <h6 className='text-center'>Scheduled Date & Time For Interview</h6>
                <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Scheduled Date:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.interviewDate ? formData.interviewDate.slice(0, 10) : 'Interview Date Not Scheduled yet'} readOnly />
                  </div>
              </div>
              </div>
              <div className='col-md-6'>
              <div className="form-group row">
                  <label style={labelStyle}  className='col-sm-6 col-form-label' htmlFor="student_name">Scheduled Time:</label>
                  <div className='col-sm-6'>
                  <input  style={inputStyle}  type="text" className="form-control" value={formData.interviewTime || 'Interview Time Not Scheduled yet' } readOnly />
                  </div>
              </div>
              </div>
              </div>
              <div className='container'>
                <p>Please bring the Interview Acknowledgement Slip when you come to meet the Principal and carefully follow the instruction given on the reverse. If the details required are not furnished the interview Acknowledgement slip <span>will be rejected</span>. The Interview Acknowledgement slip is not transferable on any terms and conditions.</p>
              </div>
            </div>
          </form>
              </div>
              )}
      <center >
                {formData && formData.name && (
                    <QRCode
                    title="Admission form"
                    value={qrCodeValue}
                    bgColor={formData.back || 'white'}
                    fgColor={formData.fore || 'black'}
                    size={formData.size || 100}
                    />
                )}
            </center>
            <div style={{textAlign: 'left' , borderTop: '1px solid black', display:'inline-block', marginRight:'10rem' }}>
                <h6>Signature Of Mother (Guardian)</h6>
            </div>
            <div style={{textAlign: 'right' , borderTop: '1px solid black', display:'inline-block', marginLeft:'6rem'}}>
                <h6>Signature Of Father (Guardian)</h6>
            </div>
      </div>
    );
  });