import '../CSS/ImageSlider.css'
import user from '../Images/logo/user.png'
import '../CSS/Navbar.css'
import React from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom';

export default function Navbar(props) {
  return (
    <nav className="navbar navbar-expand-lg bg-light">
      <div className="container-fluid mx-0"><h1 className="navbar-brand brand" > {/*<img src={logo} alt='school-logo'  className='mx-2 logo-size'/>*/}Fatehpur Public School</h1><button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button> 
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li className="nav-item">
              <Link to='/' className="nav-link active" aria-current="page">Home</Link>
            </li>
            <li className="nav-item">
              <Link to='/AboutUs' className="nav-link" >About Us</Link>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Academics
              </a>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/FeeStructure">Fee Structure</Link></li>
                <li><Link className="dropdown-item" to="/SchlTiming">School Timing</Link></li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li><Link className="dropdown-item" to="/ParentComponent">Download Document's</Link></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                New Admission
              </a>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/AdmissionForm">New Admission</Link></li>
                <li><Link className="dropdown-item" to="/AdmStatus">Check Admission Status</Link></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Facilities
              </a>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/SmartClass">Smart Class</Link></li>
                <li><Link className="dropdown-item" to="/Ssports">Sports</Link></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Gallery
              </a>
              <ul className="dropdown-menu">
                <li><Link to='/ImageGallery' className="dropdown-item" >Image Gallery</Link></li>
                <li><Link to='/VideoGallery' className="dropdown-item" >Video Gallery</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to='/TcForm' className="nav-link">Verify TC</Link>
            </li>
            <li className="nav-item">
              <Link to='/ContactUs' className="nav-link">Contact Us</Link>
            </li>
          </ul>
          <form className="d-flex mt-1" role="search">
            <Link to="../Admin/LogIn" className="nav-link" ><img src={user} alt='user-logo' width="50" height="50"  />
            </Link>
          </form>
        </div>
      </div>
    </nav>
  );
}

Navbar.propTypes = { title: propTypes.string }
