import React, { useState } from 'react';

export default function VideoUpldFrm() {
    const[videoTitle, setVideoTitle] = useState('');
    const[videoUrl, setVideoUrl] = useState('');
    const[isSubmitting, setIsSubmitting] = useState(false);
    const[showModal, setShowModal] = useState(false);
    const[successMessage, setSuccessMessage] = useState('');


    const handlevideoTitleChange = (e) =>{
      setVideoTitle(e.target.value);
    }

    const handlevideoUrlChange = (e) =>{
      setVideoUrl(e.target.value);
    }

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };

    const handleSubmit = async (e) =>{
      console.log('Handle Video Change Called');
      e.preventDefault();
      if(isSubmitting){
          return;
      }
      setIsSubmitting(true);
      setSuccessMessage('TC Uploaded successfully.');
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 2000); 
      try {
          const response = await fetch(`/Admin/VideoUpldFrm`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
          },
            body: JSON.stringify({ videoTitle, videoUrl}),
          });
      
          if (response.ok) {
            setVideoTitle('');
            setVideoUrl('');
          } else {
          }
        } catch (error) {
          console.error('Error uploading Video:', error);
        }
        setIsSubmitting(false);
    };
  return (
  <>
{showModal && (
    <div className="modal-backdrop show"></div>
  )}
    <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
            <div className="modal-header">
              <h5 className="modal-title">Success Message</h5>
            </div>
            <div className="modal-body">
              {successMessage && <p className='text-success'>{successMessage}</p>}
            </div>
          </div>
        </div>
  </div>
  <div className='container mt-5 '>
      <h2 className='text-center'>Upload 
      Video</h2>
      <form className='form-container' onSubmit={handleSubmit}>
        <div>
          <label  className='form-label' htmlFor="videoTitle">Video Title:</label>
          <input  className='form-input' type="text" id="videoTitle" value={videoTitle}  onChange={handlevideoTitleChange} required />
        </div>
        <div>
          <label  className='form-label' htmlFor="videoUrl">Video Url:</label>
          <label  className='form-label' htmlFor="videoUrl">(Open Your Video in Youtube.. Copy the part written after ?v= from url of youtube & paste in Vedio Url Field.. For Example :- https://www.youtube.com/watch?v=<span className='span'>e1WbHnjZ544&t=37s)</span></label>
          <input  className='form-input' type="text" id="videoUrl" value={videoUrl} onChange={handlevideoUrlChange} required />
        </div>
        <div className='mt-2'>
        <button className='form-submit' type="submit">Upload Video</button>
        </div>
      </form>
    </div>
   </> 
    )
}
