import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AdmissionForm() {
    const [siblings, setSiblings] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const options = ['Muslim', 'Hindu', 'Christian', 'Sikh', 'Zain']; 
    const [selectedoccupation, setSelectedOccupation] = useState('');
    const Occupation = ['Service', 'Self-employed', 'Business']; 
    const [selectedmoccupation, setSelectedMoccupation] = useState('');
    const fOccupation = ['Service', 'Self-employed', 'Business', 'House Wife']; 
    const [name, setName] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [nationality, setNation] = useState('');
    const [caste, setCaste] = useState('');
    const [category, setCategory] = useState('');
    const [language, setTongue] = useState('');
    const [fatherName, setFName] = useState('');
    const [qualification, setQualifi] = useState('');
    const [designation, setDesig] = useState('');
    const [mobNo, setMno] = useState('');
    const [whatsappNo, setWno] = useState('');
    const [motherName, setMName] = useState('');
    const [mqualification, setMqualifi] = useState('');
    const [mmobNo, setMmno] = useState('');
    const [mwhatsappNo, setMwno] = useState('');
    const [reason, setReason] = useState('');
    const [address, setAdd] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [, setIsSuccess] = useState(false);
    const history = useNavigate();
    
    
    const handleNameChange = (e) => {
      setName(e.target.value);
      };
    const handleDobChange = (e) => {
      setDob(e.target.value);
      };
    const handleGenderChange = (e) => {
    setGender(e.target.value);
      };
    const handleNationChange = (e) => {
      setNation(e.target.value);
      };
    const handleCasteChange = (e) => {
      setCaste(e.target.value);
      };
    const handleCategoryChange = (e) => {
    setCategory(e.target.value);
      };
    const handleTongueChange = (e) => {
      setTongue(e.target.value);
      };
    const handleFNameChange = (e) => {
      setFName(e.target.value);
      };
    const handleQualifiChange = (e) => {
      setQualifi(e.target.value);
      };
    const handleDesigChange = (e) => {
      setDesig(e.target.value);
      };
    const handleOccupationChange = (e) => {
      setSelectedOccupation(e.target.value);
      };
    const handleMnoChange = (e) => {
      setMno(e.target.value);
      };
    const handleWnoChange = (e) => {
      setWno(e.target.value);
      };
    const handleMNameChange = (e) => {
    setMName(e.target.value);
      };
    const handleMqualifiChange = (e) => {
      setMqualifi(e.target.value);
      };
    const handleMoccupationChange = (e) => {
      setSelectedMoccupation(e.target.value);
    };
    const handleMmnoChange = (e) => {
      setMmno(e.target.value);
      };
    const handleMwnoChange = (e) => {
    setMwno(e.target.value);
      };
    const handleReasonChange = (e) => {
      setReason(e.target.value);
      };
    const handleAddChange = (e) => {
      setAdd(e.target.value);
      };
     
    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
      };
      
    const addSibling = () => {
        // console.log('Before adding sibling:', siblings);
        setSiblings([...siblings, { sibling_name: '', sibling_age: '', sibling_class: '', sibling_section: '' }]);
        // console.log('After adding sibling:', siblings);
      };

    const handleInputChange = (index, e) => {
      const { name, value } = e.target;
      const updatedSiblings = [...siblings];
      updatedSiblings[index][name] = value;
      // console.log('Updated siblings:', updatedSiblings);
      setSiblings(updatedSiblings);
      };

    const handleSubmit = async (e) => {
      console.log('FormDataHandling called');
      e.preventDefault();
  
      if (isSubmitting) {
        return; 
      }
      setIsSubmitting(true); 
      setIsSuccess(true); 
    
      try {
        const response = await fetch(`/api/AdmissionForm`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, dob, gender, religion: selectedOption, nationality, caste, category, language, fatherName, qualification, designation, occupation: selectedoccupation, mobNo, whatsappNo, motherName, mqualification, moccupation: selectedmoccupation, mmobNo, mwhatsappNo, reason, address, siblings}),
        });
    
        if (response.ok) {
          console.log('data uploaded successfully');
          
          
          // After successful form submission, extract the `mId` from the response JSON
          const responseData = await response.json();
          console.log('API Response:', responseData);
          if (responseData.mID !== undefined) {
            const mId = responseData.mID; // Use responseData.mID
            // Now, you can redirect to the RegPdfReport component with the mId
            history(`/RegPdfReport/${mId}`);
          } else {
            // Handle the case where mId is not available in the response
            console.error('mID is missing in the API response');
            // Handle this situation as per your application's logic
          }
          setName('');
          setDob('');
          setGender('');
          setSelectedOption([]);
          setNation('');
          setCaste('');
          setCategory('');
          setTongue('');
          setFName('');
          setQualifi('');
          setDesig('');
          setSelectedOccupation([]);
          setMno('');
          setWno('');
          setMName('');
          setMqualifi('');
          setSelectedMoccupation([]);
          setMmno('');
          setMwno('');
          setSiblings([]);
          setReason('');
          setAdd('');
        } else
        {
          console.log("error in uploading")
          console.log('Error:', response.status, response.statusText);
            }
          } catch (error) {
            console.error('Error uploading data:', error);
          }
            setIsSubmitting(false);
    }   

  return (
    <>
<div className='container my-4'>
    <div className='form-infos'>
    <h4 className='text-center'>ADMISSION FORM FOR ACADEMIC YEAR: 2024 -2025</h4>
    <h6 className='text-center'>FOR CLASS PRE - NURSERY</h6>
    <p className='text-center'>If any of the particular given below found incorrect or incomplete,<br/>
    the admission form for admission will be rejected</p>
    <p className="font-weight-bold text-center text-uppercase" style={{ fontWeight: '800', textDecoration: 'underline'}} >All fields marked with asterisk (<span className='span'>*</span>)  are mandatory.</p>
    </div>
    <div className='form-fill'>
        <form className='form-inline' onSubmit={handleSubmit}>
            <div className='row'>
            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Full Name of Candidate <span className='span'>*</span>:</label>
                <input type="text" className="form-control" id="student_name" name="student_name"  value={name}  onChange={handleNameChange}  required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="date_of_birth">Date of Birth <span className='span'>*</span>:</label>
                <input type="date" className="form-control" id="date_of_birth" name="date_of_birth"  value={dob}  onChange={handleDobChange}  required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label>Gender <span className='span'>*</span>:</label>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="male" name="gender" value="Male" checked={gender === "Male"}  onChange={handleGenderChange}  />
                    <label className="form-check-label" htmlFor="male">Male</label>
                </div>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="female" name="gender" value="female" checked={gender === "female"} onChange={handleGenderChange}  />
                    <label className="form-check-label" htmlFor="female">Female</label>
                </div>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="other" name="gender "value="other" checked={gender === "other"} />
                    <label className="form-check-label" htmlFor="other">Other</label>
                </div>
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label>Religion <span className='span'>*</span>:</label>
                <select className="form-control" id="dropdown" name="religion_dropdown" value={selectedOption} onChange={handleSelectChange}>
                    <option value="">Select an option</option>
                      {options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                </select>
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Nationality <span className='span'>*</span>:</label>
                <input type="text" className="form-control" id="student_Nationality" name="student_Nationality" value={nationality} onChange={handleNationChange}  required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Caste <span className='span'>*</span>:</label>
                <input type="text" className="form-control" id="student_Caste" name="student_Caste" value={caste} onChange={handleCasteChange} required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label>Category <span className='span'>*</span>:</label>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="male" name="Category" value="sc" checked={category === "sc"} onChange={handleCategoryChange} />
                    <label className="form-check-label" htmlFor="male">SC</label>
                </div>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="female" name="Category" value="st" checked={category === "st"} onChange={handleCategoryChange} />
                    <label className="form-check-label" htmlFor="female">ST</label>
                </div>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="other" name="Category" value="obc" checked={category === "obc"}onChange={handleCategoryChange} />
                    <label className="form-check-label" htmlFor="other">OBC</label>
                </div>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="other" name="Category" value="general" checked={category === "general"} onChange={handleCategoryChange} />
                    <label className="form-check-label" htmlFor="other">GENERAL</label>
                </div>
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label>Mother Tongue <span className='span'>*</span>:</label>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="male" name="Tongue" value="hindi" checked={ language === "hindi"} onChange={handleTongueChange} />
                    <label className="form-check-label" htmlFor="male">Hindi</label>
                </div>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="female" name="Tongue" value="english" checked={ language === "emglish"} onChange={handleTongueChange}/>
                    <label className="form-check-label" htmlFor="female">English</label>
                </div>
                <div className="form-check form-check-inline">
                    <input type="radio" className="form-check-input" id="other" name="Tongue" value="other" checked={ language === "other"} onChange={handleTongueChange}/>
                    <label className="form-check-label" htmlFor="other">Other</label>
                </div>
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Fathers Name <span className='span'>*</span>:</label>
                <input type="text" className="form-control" id="Fathers_name" name="Fathers_name" value={fatherName} onChange={handleFNameChange} required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Qualification<span className='span'>*</span>:</label>
                <input type="text" className="form-control" id="Fatherst_Qualification" name="Fathers_Qualification" value={qualification} onChange={handleQualifiChange} required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Designation:</label>
                <input type="text" className="form-control" id="Fathers_desig" name="Fathers_desig" value={designation} onChange={handleDesigChange} />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Occupation<span className='span'>*</span>:</label>
                <select className="form-control" id="dropdown" name="occupation_dropdown" value={selectedoccupation} onChange={handleOccupationChange}>
                    <option value="">Select an option</option>
                      {Occupation.map((Occupation, index) => (
                        <option key={index} value={Occupation}>
                          {Occupation}
                        </option>
                      ))}
                </select>
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Mobile No<span className='span'>*</span>:</label>
                <input type="number" className="form-control" id="Fathers_number" name="Fathers_number" value={mobNo} onChange={handleMnoChange} />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Whatsapp No<span className='span'>*</span>:</label>
                <input type="number" className="form-control" id="Fathers_number" name="Fathers_number" value={whatsappNo} onChange={handleWnoChange} required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Mothers Name <span className='span'>*</span>:</label>
                <input type="text" className="form-control" id="Mothers_name" name="Mothers_name" value={motherName} onChange={handleMNameChange} required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Qualification<span className='span'>*</span>:</label>
                <input type="text" className="form-control" id="Mothers_Qualification" name="Mothers_Qualification" value={mqualification} onChange={handleMqualifiChange} required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Occupation<span className='span'>*</span>:</label>
                <select className="form-control" id="dropdown" name="Moccupation_dropdown" value={selectedmoccupation} onChange={handleMoccupationChange}>
                    <option value="">Select an option</option>
                      {fOccupation.map((fOccupation, index) => (
                        <option key={index} value={fOccupation}>
                          {fOccupation}
                        </option>
                      ))}
                </select>
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Mothers Mobile No<span className='span'>*</span>:</label>
                <input type="number" className="form-control" id="Mothers_number" name="Mothers_number" value={mmobNo} onChange={handleMmnoChange} required />
            </div>
            </div>

            <div className='col-md-6'>
            <div className="form-group">
                <label htmlFor="student_name">Mothers Whatsapp No<span className='span'>*</span>:</label>
                <input type="number" className="form-control" id="Mothers_number" name="Mothers_number" value={mwhatsappNo} onChange={handleMwnoChange} required />
            </div>
            </div>

            <div className="form-group">
                <label htmlFor="address">Give The details of Siblings/ Real brother or sister <span className='span'>(Not Cousins)</span> If studying in Fatehpur Public School/ New Fatehpur Public School, Fatehpur:</label>
                {siblings.map((sibling, index) => (
                <div className="row"  key={index}>
                    <div className="col">
                        <label htmlFor="sibling_name">NAME</label>
                        <input type="text" className="form-control" id="sibling_name" name="sibling_name"  onChange={(e) => handleInputChange(index, e)} />
                    </div>
                    <div className="col">
                        <label htmlFor="sibling_age">AGE</label>
                        <input type="text" className="form-control" id="sibling_age" name="sibling_age" onChange={(e) => handleInputChange(index, e)}/>
                    </div>
                    <div className="col">
                        <label htmlFor="sibling_class">CLASS</label>
                        <input type="text" className="form-control" id="sibling_class" name="sibling_class"   onChange={(e) => handleInputChange(index, e)}/>
                    </div>
                    <div className="col">
                        <label htmlFor="sibling_section">SECTION</label>
                        <input type="text" className="form-control" id="sibling_section" name="sibling_section"   onChange={(e) => handleInputChange(index, e)}/>
                    </div>
                </div>
                ))}
                <button type="button" className="btn btn-primary my-3" id="add-sibling" onClick={addSibling}>Add Sibling</button> 
            </div>

            <div className="form-group">
                <label htmlFor="address">Reason For Seeking Admission In This School:</label>
                <textarea className="form-control" id="address" name="reason" rows="4" value={reason} onChange={handleReasonChange} ></textarea>
            </div>

            <div className="form-group">
                <label htmlFor="address">Address <span className='span'>*</span>:</label>
                <textarea className="form-control" id="address" name="address" rows="4" value={address} onChange={handleAddChange} required></textarea>
            </div>
            </div>
            <div className="text-center"><button className='btn btn-danger' type="submit" name="submit">Submit</button></div>
        </form>
    </div>
</div>
    </>
  )
}
